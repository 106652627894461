import { Puff } from "react-loader-spinner"

export function Spinner(): JSX.Element {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Puff color="#19A3D3" height={200} width={200} />
    </div>
  )
}
