import { navigate } from "@reach/router"
import axios from "axios"
import { useCallback, useEffect } from "react"
import { StringParam, useQueryParams } from "use-query-params"

import { Spinner } from "../../components/Spinner"
import { SnipcartCreatePaymentBody } from "../../types/snipcart-api"

export default function ProcessAzulPage() {
  const [query] = useQueryParams({
    paymentSessionId: StringParam,
    publicToken: StringParam,
    Amount: StringParam,
    AuthHash: StringParam,
    AuthorizationCode: StringParam,
    AzulOrderId: StringParam,
    CardNumber: StringParam,
    CustomOrderId: StringParam,
    DataVaultBrand: StringParam,
    DataVaultExpiration: StringParam,
    DataVaultToken: StringParam,
    DateTime: StringParam,
    Discounted: StringParam,
    ErrorDescription: StringParam,
    IsoCode: StringParam,
    Itbis: StringParam,
    OrderNumber: StringParam,
    RRN: StringParam,
    ResponseCode: StringParam,
    ResponseMessage: StringParam,
  })

  const processPayment = useCallback(async (): Promise<string> => {
    const { paymentSessionId, publicToken, RRN } = query

    if (!paymentSessionId || !publicToken) throw new Error("no paymentSessionId")

    const createPaymentBody: SnipcartCreatePaymentBody = {
      publicToken,
      paymentSessionId,
      state: query.ResponseMessage === "APROBADA" ? "processed" : "failed",
      transactionId: RRN || `failed-${paymentSessionId}`,
      error:
        query.ResponseMessage === "DECLINADA"
          ? {
              code: "card_declined",
              message: "La tarjeta ha sido declinada. Por favor intenta de nuevo",
            }
          : undefined,
    }

    const { data: createPaymentResponse } = await axios.get(
      "https://casabipaymentsapi.gatsbyjs.io/api/payments/process-azul",
      { params: createPaymentBody },
    )

    return createPaymentResponse.returnUrl
  }, [query])

  useEffect(() => {
    const { publicToken } = query
    if (!publicToken) return

    processPayment()
      .then(redirectUrl => {
        navigate(redirectUrl, { replace: true })
      })
      .catch(reason => {
        console.error({ reason })
        navigate("https://casabi.do?failed=true", { replace: true })
      })
  }, [processPayment, query])

  return <Spinner />
}
